/* @font-face {
  font-family: Lodestone;
  src: url(./assets/fonts/Lodestone.ttf);
}

@font-face {
  font-family: SaintRegus;
  src: url(./assets/fonts/SaintRegus.otf);
} */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  font-family: "Rubik", sans-serif;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

ol,
ul {
  list-style: none;
}

button {
  cursor: pointer;
  transition: all .26s ease;
}
button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-skeleton-title {
  width: 100% !important;
  height: 47px !important;
}

.ant-skeleton-content h3,
.ant-skeleton-content .ant-skeleton-paragraph li {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.ant-skeleton-paragraph {
  margin-block-start: 0 !important;
}

.ant-spin-dot-item {
  background-color: rgba(255, 255, 255, 1) !important;
}

#tv-attr-logo {
  display: none;
}

.modal-success .ant-modal-content {
  background-color: #191919;
}
.modal-success .ant-modal-close svg {
  fill: #fff;
}
.modal-success .ant-modal-content::before,
.modal-success .ant-modal-content::after {
  content: "";
  position: absolute;
  pointer-events: none;
}
.modal-success .ant-modal-content::before {
  width: 556px;
  height: 556px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(38.23% 38.23% at 50% 50%, rgba(113, 255, 137, 0.18) 0%, rgba(113, 255, 137, 0.00) 100%);
  filter: blur(27.049999237060547px);
}