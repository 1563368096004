.pool-modal .ant-modal-content {
  border-radius: 6px;
  border: 1px solid #606060;
  background: #191919;
}
@media screen and (max-width: 1024px) {
  .pool-modal .ant-modal-content {
    padding: 15px;
  }
}
.pool-modal .ant-modal-header {
  background: unset;
}
.pool-modal .ant-modal-close {
  color: #fff;
  transition: opacity .26s ease;
}
.pool-modal .ant-modal-close:hover {
  color: #fff;
  opacity: .7;
}
