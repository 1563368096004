.modal-member .ant-modal-content {
  background-color: #111015;
  height: 70vh;
  overflow: auto;
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.modal-member .ant-modal-close {
  color: #FFF;
}
.modal-member .ant-modal-close-x {
  font-size: 20px;
}